import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PageSEO from '../components/PageSEO';
import { LocalizedSEO } from '../fragments';

import Layout from '../components/ui/Layout';
import Section, { Module } from '../components/ui/Section';
import TopBanner from '../components/ui/TopBanner';
import VslHero, { VslHeroProps } from '../components/ui/VslHero';
import {
  ALLOWED_BANNERS,
  BANNER_QUERY_PARAM,
  FILE_DOWNLOAD_URLS_BY_PARAM_VALUE,
  FILE_QUERY_PARAM,
} from '../constants';
import { useGlobalState } from '../state/globalStateContext';
import { clsx, removeParamsFromQueryParams } from '../utils/utils';
import * as styles from './VslPage.module.scss';

export const query = graphql`
  query GetVslPageById($id: String!) {
    sanityLpVslPage(id: { eq: $id }) {
      hero {
        ...VslHero
      }
      sections {
        title
        useTitleOnlyAsLabel
        description
        content {
          ...Modules
        }
      }
      typeformId
      seo {
        ...SEO
      }
    }
  }
`;

interface VslPageProps {
  data: {
    sanityLpVslPage: {
      hero: VslHeroProps;
      sections: Array<{
        title: string;
        useTitleOnlyAsLabel: boolean;
        description: string;
        content: Array<Module>;
      }>;
      typeformId?: string;
      seo: LocalizedSEO;
    };
  };
}

const VslPage = ({ data }: VslPageProps): React.ReactElement => {
  const { hero, sections, seo } = data.sanityLpVslPage;
  const { theme } = useGlobalState();
  const [banner, setBanner] = useState<null | typeof ALLOWED_BANNERS[number]>(null);
  const [downloadFile, setDownloadFile] = useState<null | string>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bannerParamValue = urlSearchParams.get(BANNER_QUERY_PARAM);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);

    if (bannerParamValue || fileParamValue) {
      // Remove the banner and file query param
      removeParamsFromQueryParams([BANNER_QUERY_PARAM, FILE_QUERY_PARAM]);
    }

    if (
      bannerParamValue &&
      ALLOWED_BANNERS.includes(bannerParamValue as typeof ALLOWED_BANNERS[number]) &&
      (bannerParamValue !== 'guide-downloading' || fileParamValue)
    ) {
      setBanner(bannerParamValue as typeof ALLOWED_BANNERS[number]);
    }

    if (fileParamValue && FILE_DOWNLOAD_URLS_BY_PARAM_VALUE.hasOwnProperty(fileParamValue)) {
      setDownloadFile(fileParamValue);
      window.open(FILE_DOWNLOAD_URLS_BY_PARAM_VALUE[fileParamValue]);
    }
  }, []);

  return (
    <Layout isVslPage>
      <div className={clsx(styles.container)} data-theme={theme === 'light' ? 'light' : ''}>
        <PageSEO pageSEO={seo} />
        {banner === 'guide-thank-you' && (
          <TopBanner isVslPage onClose={() => setBanner(null)}>
            Thank you for your interest! Your guide is on the way. <br></br>Please check your email.
          </TopBanner>
        )}

        {banner === 'guide-downloading' && downloadFile && (
          <TopBanner isVslPage onClose={() => setBanner(null)}>
            Your guide is downloading! <br></br>
            <a href={FILE_DOWNLOAD_URLS_BY_PARAM_VALUE[downloadFile]} download>
              Click here if the download didn't start
            </a>
          </TopBanner>
        )}
        <VslHero {...hero} className={clsx(styles.hero, banner && styles.withBanner)} />
        <div className={styles.contentContainer}>
          {sections.map((section, sectionIndex) => (
            <Section
              key={sectionIndex}
              sectionIndex={sectionIndex}
              isVslPage
              typeFormId={data.sanityLpVslPage.typeformId}
              {...section}
            ></Section>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default VslPage;
