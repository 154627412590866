import React, { createContext, useContext, useEffect, useState } from 'react';
import { removeParamsFromQueryParams } from '../utils/utils';

type Theme = 'dark' | 'light';
const THEMES: Array<Theme> = ['dark', 'light'];
const DEFAULT_THEME: Theme = 'light';

export interface GlobalState {
  homepageSlug: string;
  setHomepageSlug: (homepageSlug: string) => void;
  hideSignInButton: boolean;
  setHideSignInButton: (hideSignInButton: boolean) => void;
  theme: Theme | null;
  setTheme: (theme: Theme) => void;
}

// We assume this GlobalStateContext will never be used unless inside
// the GlobalStateContext.Provider, and so the default is never used.
export const GlobalStateContext = createContext<GlobalState>({} as GlobalState);

export const useGlobalState = () => useContext(GlobalStateContext);

interface StateProviderProps {
  children: React.ReactNode;
}

export function GlobalStateProvider({ children }: StateProviderProps) {
  const [homepageSlug, setHomepageSlug] = useState<string>('/');
  const [hideSignInButton, setHideSignInButton] = useState(false);
  const [theme, _setTheme] = useState<Theme | null>(null);
  const [scheduleOnceCalendar, setScheduleOnceCalendar] = useState<string | null>(null);

  function setTheme(theme: Theme) {
    _setTheme(theme);
    window.localStorage.setItem('theme', theme);
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const themeParamValue = urlSearchParams.get('theme');
    if (themeParamValue && THEMES.includes(themeParamValue as typeof THEMES[number])) {
      setTheme(themeParamValue as typeof THEMES[number]);
      removeParamsFromQueryParams(['theme']);
    } else {
      const localStorageTheme = window.localStorage.getItem('theme');
      if (!localStorageTheme || !THEMES.includes(localStorageTheme as typeof THEMES[number])) {
        setTheme(DEFAULT_THEME);
        window.localStorage.removeItem('theme');
      } else {
        setTheme(localStorageTheme as typeof THEMES[number]);
      }
    }
  }, []);

  const globalState: GlobalState = {
    homepageSlug,
    setHomepageSlug,
    hideSignInButton,
    setHideSignInButton,
    theme,
    setTheme,
    scheduleOnceCalendar,
    setScheduleOnceCalendar,
  };

  return <GlobalStateContext.Provider value={globalState}>{children}</GlobalStateContext.Provider>;
}
