import React, { useEffect } from 'react';
import { useGlobalState } from '../../state/globalStateContext';
import { useActivateGoogleOptimize } from '../../utils/hooks';
import { clsx } from '../../utils/utils';
import Footer from '../Footer';
import Header from './Header';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  signInButtonText?: string;
  anchorButtonText?: string;
  privacyPolicyPage?: {
    slug: {
      current: string;
    };
  };
  onHeaderAnchorButtonClick?: () => void;
  children: React.ReactNode;
  isVslPage?: boolean;
  className?: string;
}

const Layout = ({
  signInButtonText,
  anchorButtonText,
  privacyPolicyPage,
  onHeaderAnchorButtonClick,
  children,
  isVslPage,
  className,
}: LayoutProps): React.ReactElement => {
  const { setHomepageSlug } = useGlobalState();
  const { theme } = useGlobalState();
  const isHidden = useActivateGoogleOptimize() || theme === null;

  useEffect(() => {
    // If we have an entry_homepage_slug in the local storage, save it in the global state
    const entryHomepageSlug = window.localStorage.getItem('entry_homepage_slug');
    if (entryHomepageSlug) {
      setHomepageSlug(entryHomepageSlug);
    }
  }, []);

  return (
    <div className={styles.container} style={isHidden ? { opacity: 0 } : undefined}>
      <Header
        signInButtonText={signInButtonText && !isVslPage ? signInButtonText : 'Sign in'}
        anchorButtonText={anchorButtonText}
        onAnchorButtonClick={onHeaderAnchorButtonClick}
        isVslPage={isVslPage}
      ></Header>
      <div className={clsx(styles.main, className)}>{children}</div>
      <Footer
        privacyPolicyPage={isVslPage ? undefined : privacyPolicyPage}
        padding={isVslPage ? '36px' : '40px'}
        withPageTheme={isVslPage ? true : false}
        additionalCopyrightText={isVslPage ? '' : 'Viral by'}
      ></Footer>
    </div>
  );
};

export default Layout;
